@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-gray-800;
}

#root{
  @apply overflow-hidden;
}

.ReactModal__Overlay {
  @apply flex justify-center items-center h-screen transition-all ease-out duration-75;
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  opacity: 0;
  transform: scale(0);
  transition: opacity 200ms, transform 200ms;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: scale(1);
  transition: opacity 200ms, transform 200ms;
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: scale(0);
  transition: opacity 200ms, transform 200ms;
}